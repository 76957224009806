
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import CreditPolicy from "@/types/credit-policy";
import { DataTableHeader } from "vuetify";
import CreditPolicyService from "@/services/credit-policy-service";
import CreditPolicyManageModal from "@/components/credit-policies/CreditPolicyManageModal.vue";
import CreditPolicyDeleteModal from "@/components/credit-policies/CreditPolicyDeleteModal.vue";

import Modal from "@/components/Modal.vue";

@Component({
  components: {
    CreditPolicyManageModal,
    CreditPolicyDeleteModal,
    Modal,
    Page
  }
})
export default class CreditPoliciesManage extends Vue {
  service: CreditPolicyService;
  creditPolicies: CreditPolicy[];
  headers: Array<DataTableHeader>;
  addCreditPolicy = false;
  editCreditPolicy?: CreditPolicy | null = null;
  showCreditPolicies = false;
  loading = false;
  deleteCreditPolicy?: CreditPolicy | null = null;

  constructor() {
    super();
    this.service = CreditPolicyService.getInstance();
    this.headers = [
      { text: "Política", value: "identifier", sortable: false },
      { text: "Segmento", value: "segment.name", sortable: false },
      { text: "Descrição", value: "description", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.creditPolicies = [];
  }

  async created(): Promise<void> {
    await this.fetchCreditPolicies();
  }

  async fetchCreditPolicies(): Promise<CreditPolicy[]> {
    this.loading = true;
    const [error, creditPolicies] = await this.service.listCreditPolicies();
    if (error || !creditPolicies) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de políticas de crédito"
      });
    } else {
      this.creditPolicies = creditPolicies;
    }
    this.loading = false;
    return this.creditPolicies;
  }

  async remove(creditPolicyId: number): Promise<void> {
    this.closeModal();
    this.loading = true;
    const [error, creditPolicy] = await this.service.removeCreditPolicy(
      creditPolicyId
    );
    if (error) {
      this.$notify({
        type: "error",
        text: "Não foi possível remover a política de crédito"
      });
    }
    this.loading = false;

    this.fetchCreditPolicies();
  }

  closeModal(): void {
    this.addCreditPolicy = false;
    this.editCreditPolicy = null;
    this.deleteCreditPolicy = null;
    this.$emit("close");
  }
}
