var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"title":"Relatórios Gerenciais"}},[_c('div',{staticClass:"pt-6"},[(_vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_GRUPOS']))?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'groups-loans'}}},[_vm._v(" Empréstimos Grupo ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_EMPRESAS']))?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'companies-loans'}}},[_vm._v(" Empréstimos Empresa ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_COLABORADORES']))?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'borrowers-loans'}}},[_vm._v(" Empréstimos Colaborador ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_DETALHES']))?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'loan-installments'}}},[_vm._v(" Detalhes Empréstimo Colaborador ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_PARCEIRO']))?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'partners-commissions'}}},[_vm._v(" Acompanhamento das comissões ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_REMESSA']) && _vm.isAdminGooroo)?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'remittance-file-lots'}}},[_vm._v(" Arquivos de Lotes Fundo ")]):_vm._e(),(
        _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMO_REMESSA']) &&
        _vm.isAdminGooroo
      )?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'fund-lots'}}},[_vm._v(" Lotes Fundo ")]):_vm._e(),(_vm.hasPermissions(['LISTAR_OCORRENCIA_REMESSA']) && _vm.isAdminGooroo)?_c('v-btn',{staticClass:"mb-3",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){_vm.active = 'fund-lots-details'}}},[_vm._v(" Detalhes Lotes Fundos ")]):_vm._e()],1),(
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_GRUPOS']) &&
      _vm.active === 'groups-loans'
    )?_c('GroupsLoansReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_EMPRESAS']) &&
      _vm.active === 'companies-loans'
    )?_c('CompaniesLoansReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_COLABORADORES']) &&
      _vm.active === 'borrowers-loans'
    )?_c('BorrowersLoansReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMOS_COLABORADORES']) &&
      _vm.active === 'loan-installments'
    )?_c('LoanInstallmentsReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_PARCEIRO']) && _vm.active === 'partners-commissions'
    )?_c('PartnersCommissionsReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_REMESSA']) && _vm.active === 'remittance-file-lots'
    )?_c('FilesRemittanceFundsReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_RELATORIO_EMPRESTIMO_REMESSA']) &&
      _vm.active === 'fund-lots'
    )?_c('RemittanceFundsReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e(),(
      _vm.hasPermissions(['LISTAR_OCORRENCIA_REMESSA']) &&
      _vm.active === 'fund-lots-details'
    )?_c('RemittancesFundsDetailsReport',{on:{"close":function($event){_vm.active = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }