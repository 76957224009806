
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import CreditPolicy from "@/types/credit-policy";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import CreditPolicyService, {
  CreateCreditPolicyParams
} from "@/services/credit-policy-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

import dayjs from "@/plugins/day-js";
import Segment from "@/types/segment";

@Component({
  components: { ValidationObserver, Modal },
  directives: { mask }
})
export default class CreditPolicyModal extends Vue {
  service: CreditPolicyService;
  @Prop() readonly creditPolicy?: CreditPolicy | undefined;
  loading: boolean = false;
  form: CreateCreditPolicyParams = {
    segmentName: "",
    identifier: "",
    description: ""
  };

  segments: Segment[];

  constructor() {
    super();
    this.service = CreditPolicyService.getInstance();
    this.segments = [];

    if (this.creditPolicy) {
      this.form = {
        segmentName: this.creditPolicy.segment.name,
        identifier: this.creditPolicy.identifier,
        description: this.creditPolicy.description
      };
    }
  }

  async created(): Promise<void> {
    this.loadSegments();
  }

  async loadSegments(): Promise<void> {
    this.loading = true;
    const [error, segments] = await this.service.listSegments();
    this.loading = false;
    if (error || !segments) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.segments = segments;
    }
  }

  async save(): Promise<void> {
    let error, creditPolicy;
    this.loading = true;
    if (this.creditPolicy) {
      // Update existing creditPolicy
      [error, creditPolicy] = await this.service.updateCreditPolicy(
        this.creditPolicy.id,
        this.form
      );
    } else {
      // Create new creditPolicy
      [error, creditPolicy] = await this.service.createCreditPolicy(this.form);
    }
    this.loading = false;
    if (!error) {
      this.$notify({ type: "success", text: "Política salva com sucesso" });
      this.$emit("input", creditPolicy);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
  }

  close(): void {
    this.$emit("close");
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get formTitle(): string {
    return this.creditPolicy
      ? "Editar política de crédito"
      : "Nova política de crédito";
  }
}
